<template>
    <el-drawer
        title="添加角色"
        :visible.sync="drawer"
        :modal="true"
        :wrapperClosable="false"
        size="600px"
        @close="closeDrawer"
        :modal-append-to-body="false"
        :direction="direction">

        <!-- title -->
        <div class="drawer_form">
            
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="120px" class="demo-ruleForm">
                
                <div class="form-body">
                    <overlay-scrollbars style="width: 100%;height: 100%;" class="el-fade-in">
                        <el-form-item label="角色名称" prop="name">
                            <el-input type="text" v-model="form.name" autocomplete="off"></el-input>
                        </el-form-item>
                        <el-form-item label="角色描述" prop="">
                            <el-input type="textarea" v-model="form.description" rows="6" autocomplete="off"></el-input>
                        </el-form-item>
                        <el-form-item label="slug" prop="">
                            <el-input type="text" v-model="form.slug" autocomplete="off"></el-input>
                        </el-form-item>
                    </overlay-scrollbars>
                </div>

                <div class="form-foot d-flex a-center j-center">
                    <el-button @click="onSubmit" type="primary" class="" style="width:200px">添加</el-button>
                </div>
            </el-form>
        </div>

    </el-drawer>
    
</template>

<script>
import {create} from '@/request/api/role'
export default {
    data(){
        return {
            drawer: true, //表单
            direction: 'rtl',//抽屉方向
            loading:false,
            form:{
                description: "",
                name: "",
                slug: ""
            },
            
            rules:{
                name:[
                    { required: true, message: '角色名称必填', trigger: 'blur' },
                ],
            }
        }
    },
    created(){

    },
    methods:{
        onSubmit(){
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    this.req()
                } else {
                    return false;
                }
            });
        },
        
        //req
        req(){
            create(this.form).then(res=>{
                if(res.code!=0) return this.$notify({title: '失败',message:res.msg,type: 'error'});
                this.$notify({title: '成功',message: "SUCCESS",type: 'success'});
                this.$router.go(-1)
            })           
        },



        //重置表单
        resetForm(formName) {
            this.$refs[formName].resetFields();
        },

        //关闭抽屉
        closeDrawer(){
            this.$router.go(-1)
        }
    }

}
</script>


<style lang="less" scoped>
.drawer_form {
    width: 96%;
    margin: auto;
    .drawer_form_title {
        color: #17233d;
        font-size: 22px;
        font-weight: 400;
        text-align: center;
        line-height: 48px;
        margin-bottom: 20px;
    }

    .drawer_line {
        margin-bottom: 30px;
        .drawer_line_text {
            width: 120px;
            margin-right: 20px;
            text-align: right;
            color: #515a6e;
        }    
    }

}
.form-items {
    background-color:#f5f6fb;padding:15px 10px;height:500px;
}

/deep/ .form-items .el-form-item ,/deep/ .form-items .el-form-item .el-form-item__content{
    width: 100%;
}

</style>

